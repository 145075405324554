import React, { FC, useEffect, useState } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { Spinner } from 'wix-ui-tpa';
import { st, classes } from './Widget.st.css';
import { CodeEditorState } from '../types';
import { CodeEditorActions } from '../actions/actions';
import { CodeEditorApi } from '../codeEditorApi/codeEditorApi';
import { Helmet } from 'react-helmet';
import MonacoEditor from './MonacoEditor';

export type ControllerProps = {
  actions: CodeEditorActions;
  codeEditorApi: CodeEditorApi;
} & CodeEditorState;

const Widget: FC<WidgetProps<ControllerProps>> = (props: ControllerProps) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    const init = async () => {
      await props.actions.initPyodide();
    };
    setIsClient(true);
    init();
  }, []);

  if (!isClient) {
    return (
      <div>
        <Spinner diameter={50} isCentered={true} />
      </div>
    );
  }

  try {
    return (
      <div
        className={st(classes.root, {})}
        data-hook="WixCodeEditorComponent-wrapper"
      >
        <Helmet>
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/9000.0.1/themes/prism-okaidia.min.css"
            rel="stylesheet"
          />
        </Helmet>
        <div>
          <MonacoEditor
            value={props.code}
            onChange={(text: string) => {
              props.actions.setCode(text);
            }}
          />
        </div>
      </div>
    );
  } catch (err) {
    console.log(err);
    return (
      <div>
        <Spinner />
      </div>
    );
  }
};

export default Widget;
