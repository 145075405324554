import { StyleParamType, createStylesParams } from '@wix/tpa-settings';

export type IStylesParams = {
  editorHeight: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  editorHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 300,
  },
});
