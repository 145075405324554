import React, { FC, useState } from 'react';
import Editor from '@monaco-editor/react';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../stylesParams';

export type CodeEditorProps = {
  value: string;
  onChange: (value: string) => void;
};

const MonacoEditor: FC<CodeEditorProps> = (props: CodeEditorProps) => {
  const [value, setValue] = useState(props.value);
  const settings = useStyles();

  const onChange = (value: string | undefined, event: any) => {
    const { onChange } = props;

    setValue(value!);
    onChange(value!);
  };

  return (
    <Editor
      value={value}
      language="python"
      theme="vs-dark"
      options={{
        contextmenu: false,
        minimap: { enabled: false },
        lineNumbers: 'off',
        wordWrap: 'on',
      }}
      onChange={(code, event) => onChange(code, event)}
      height={`${settings.get(stylesParams.editorHeight)}px`}
    />
  );
};

export default MonacoEditor;
